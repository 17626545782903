<template>
  <v-list :class="[haveSite ? 'text-start' : 'text-center']" dense nav tile>
    <v-list-item>
      <v-list-item-avatar>
        <v-img src="https://via.placeholder.com/100x100"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ $auth.user().first_name }} {{ $auth.user().last_name }}</v-list-item-title>
        <v-list-item-subtitle>{{ $auth.user().email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider/>

    <v-list-item-group v-if="!haveSite" v-model="group" active-class="secondary white--text">
      <v-btn :to="{name: 'site'}" class="my-3" color="error" large outlined @click="itemClick">
        {{ $vuetify.lang.t('$vuetify.general.becomeAVendor') }}
      </v-btn>

      <kurcc-app-bar-navigation-drawer-log-out/>
    </v-list-item-group>

    <v-list-item-group v-else v-model="group" active-class="secondary white--text">
      <v-list-item :to="{name: 'profile'}">
        <v-list-item-avatar>
          <v-icon>mdi-account</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          {{ $vuetify.lang.t('$vuetify.pages.home.drawerMenu.profile') }}
        </v-list-item-title>
      </v-list-item>

<!--      <v-list-item :to="{name: 'analytics'}">-->
<!--        <v-list-item-avatar>-->
<!--          <v-icon>mdi-google-analytics</v-icon>-->
<!--        </v-list-item-avatar>-->
<!--        <v-list-item-title>-->
<!--          {{ $vuetify.lang.t('$vuetify.pages.home.drawerMenu.analytics') }}-->
<!--        </v-list-item-title>-->
<!--      </v-list-item>-->

      <v-list-item :to="{name: 'branches'}">
        <v-list-item-avatar>
          <v-icon>mdi-source-branch</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          {{ $vuetify.lang.t('$vuetify.pages.home.drawerMenu.branches') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item :to="{name: 'gallery'}">
        <v-list-item-avatar>
          <v-icon>mdi-image-multiple</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          {{ $vuetify.lang.t('$vuetify.pages.home.drawerMenu.gallery') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item :to="{name: 'inspections'}">
        <v-list-item-avatar>
          <v-icon>mdi-text-box-check</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          {{ $vuetify.lang.t('$vuetify.pages.home.drawerMenu.inspections') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item :to="{name: 'favorites'}">
        <v-list-item-avatar>
          <v-icon>mdi-heart</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>
          {{ $vuetify.lang.t('$vuetify.pages.home.drawerMenu.favorites') }}
        </v-list-item-title>
      </v-list-item>

      <kurcc-app-bar-navigation-drawer-log-out/>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'KurccAppBarNavigationDrawerItems',
  components: {
    KurccAppBarNavigationDrawerLogOut: () => import('@/modules/app/components/KurccAppBarNavigationDrawerLogOut')
  },
  data () {
    return {
      group: null
    }
  },
  computed: {
    haveSite () {
      return this.$auth.user().site !== null
    }
  },
  watch: {
    group () {
      this.itemClick()
    }
  },
  methods: {
    itemClick () {
      this.$emit('item-click')
    }
  }
}
</script>
